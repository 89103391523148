





import { defineComponent, computed } from '@nuxtjs/composition-api';
import LazyHydrate from 'vue-lazy-hydration';

export default defineComponent({
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  components: {
    LazyHydrate
  },
  setup(props) {
    const scriptContent = computed(() => {
      const scriptTag = props.data?.plain_text;
      // add defer attribute if it's not already there
      if (!scriptTag.includes('defer')) {
        return scriptTag.replaceAll('<script', '<script defer');
      } else {
        return scriptTag;
      }
    });

    return {
      scriptContent
    };
  }
});
